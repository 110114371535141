/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7iw7pqho5nhonaveuwsc4oywcm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-upweljo3enfvfld6rr5udoscnu",
    "aws_cognito_identity_pool_id": "us-east-1:accf85a1-d819-48f6-96bd-2a071d11444c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_p2h9u34wL",
    "aws_user_pools_web_client_id": "hb4tngr1ph0tufsj513692hhe",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pc-media213017-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
