import './Header.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  Heading,
  View,
  Button
} from "@aws-amplify/ui-react";
import NavBar from '../NavBar/NavBar';


const Header = () => {
    return (
        <View className="main-header">
            <div className="header-image">
                <img src={ require('../../images/PTG-logos_white.png') } width={100} height={104} />
            </div>
            <NavBar></NavBar>
        </View>
    )
}

export default Header