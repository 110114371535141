import create from 'zustand'
import { persist } from 'zustand/middleware'
const main = require('./video-config/main.json')

export const useStateStore = create(persist((set, get) => ({
    video: main.beginner[0],
    setVideo: (newVideo) => set((state) => ({ video: newVideo }))
}),
{
    name: 'playertwo-golf-storage',
    getStorage: () => localStorage
}
))