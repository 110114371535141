import './VideoPlayer.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  Heading,
  View,
  Text
} from "@aws-amplify/ui-react";
import PropTypes from 'prop-types'


const VideoPlayer = (props = { video: { "videoId": "lJgy0_tM7so", "title": "How to swing a golf club (simple way)", "description": "Swing basics", "category": "basics" }}) => {
    const video = props.video
    let videoId = video.videoId
    let srcUrl = `https://www.youtube.com/embed/${videoId}`
    return (
        <View className="Video-Player">
            <div class="container">
            <iframe
                class="responsive-iframe"
                src={srcUrl}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                title="video"
            />{" "}
            </div>
        </View>
    )
}

VideoPlayer.propTypes = {
    video: PropTypes.object
}

export default VideoPlayer