import './Profile.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  Heading,
  View,
  Text,
  withAuthenticator
} from "@aws-amplify/ui-react";
import Header from '../../components/Header/Header'


function Profile () {
    return (
        <View className="App">
            <Header></Header>
            <Heading level={1}>Welcome to Player Two Golf.. Website is currently under construction.</Heading>
            <div>
                <Text>Here is your profile info.</Text>
            </div>
        </View>
    )
}

export default withAuthenticator(Profile)