import './NavBar.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  View
} from "@aws-amplify/ui-react";
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
  } from './NavbarElements';


const NavBar = () => {
    return (
        <View className="navbar">
            <Nav>
                <Bars />
        
                <NavMenu>
                <NavLink to='/' activeStyle>
                    Home
                </NavLink>
                <NavLink to='/about' activeStyle>
                    About
                </NavLink>
                <NavLink to='/viewer' activeStyle>
                    Video Viewer
                </NavLink>
                {/* Second Nav */}
                {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
                </NavMenu>
                <NavBtn>
                <NavBtnLink to='/profile'>Profile</NavBtnLink>
                </NavBtn>
            </Nav>
        </View>
    )
}

export default NavBar