import './ControlPanel.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  Heading,
  View,
  Button
} from "@aws-amplify/ui-react";
const main = require('../../video-config/main.json')
const useStateStore = require('../../stateStore').useStateStore


const ControlPanel = () => {
    const videoList = main.beginner
    const changeVideo = useStateStore((state)=> state.setVideo)
    return (
        <View className="control-panel">
            <Heading level={5}>Video Guide</Heading>
            <div class="container-two">
            {videoList.map(function (object, i) {
                return <Button type="text" onClick={() => changeVideo({ videoId: object.videoId, title: object.title, description: object.description, category: object.category})} obj={object} key={i} className="control-button">
                    {object.description}
                </Button>
            })}
            </div>
        </View>
    )
}

export default ControlPanel