import './Viewer.css';
import React, { useEffect } from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator
} from "@aws-amplify/ui-react";
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'
import ControlPanel from '../../components/ControlPanel/ControlPanel'
import Header from '../../components/Header/Header'
const useStateStore = require('../../stateStore').useStateStore


function Viewer () {
    const video = useStateStore((state) => state.video)
    useEffect(() => {
        console.log('Testing use effect');
    })
    return (
        <div className="video-view">
            <Header></Header>
            <div className="viewer-body">
            <ControlPanel className="control-panel"></ControlPanel>
            <VideoPlayer className="video-player" video={video}></VideoPlayer>
            </div>
        </div>
    )
}

export default withAuthenticator(Viewer)