import './Home.css';
import React, { useEffect } from 'react';
import logo from "../../images/PTG-logos_transparent.png";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Heading,
  Image,
  View,
  withAuthenticator
} from "@aws-amplify/ui-react";
import Header from '../../components/Header/Header'


function Home ({ signOut }) {
    useEffect(() => {
        console.log('Testing use effect');
    })
    return (
        <View className="App">
            <Header></Header>
            <Image src={logo} className="App-logo" alt="logo" />
            <Heading level={1}>Welcome to Player Two Golf.. Website is currently under construction.</Heading>
            <Button onClick={signOut}>Sign Out</Button>
        </View>
    )
}

export default withAuthenticator(Home)